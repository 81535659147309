import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";

const root = ReactDOM.createRoot(document.getElementById("root"));

const reactTarget = { name: "ReactClientSDK", identifier: "reactclientsdk" };
const sdkApiKey = process.env.REACT_APP_HARNESS_KEY;

const isLocalDevelopment = process.env.REACT_APP_ENV === "dev";

const RootComponent = isLocalDevelopment ? (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FFContextProvider apiKey={sdkApiKey} target={reactTarget}>
          <App />
        </FFContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
) : (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FFContextProvider apiKey={sdkApiKey} target={reactTarget}>
        <App />
      </FFContextProvider>
    </PersistGate>
  </Provider>
);
root.render(RootComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
