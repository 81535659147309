import { Box, Grid } from "@mui/material";
import SelectQuestionComponent from "./SelectQuestionComponent";
import MultiSelectQuestionComponent from "./MultiSelectQuestionComponent";

function GetQuestionForm(questions) {
  return (
    <>
      {questions?.questions?.map((question, index) => {
        if (!question?.isSuggested && !question?.hideQuestion) {
          switch (question.type) {
            case "select":
              return (
                <Grid item xs={12} md={12} lg={10}>
                  <SelectQuestionComponent
                    question={question}
                    key={index}
                    questionsObject={questions}
                  />
                </Grid>
              );
            case "multiSelect":
              return (
                <>
                  {question?.skillMultiselect === true ? (
                    <>
                      {/* <SkillMultiSelectQuestionComponent question={question} key={index} /> */}
                    </>
                  ) : question?.dynamicMultiSelect ? (
                    ""
                  ) : (
                    <Grid item xs={12} md={12} lg={4}>
                      {/* <DynamicMultiSelectQuestionComponent key={index} question={question} /> */}
                      <MultiSelectQuestionComponent
                        question={question}
                        key={index}
                      />
                    </Grid>
                  )}
                </>
              );

            default:
              return "";
          }
        }
      })}
    </>
  );
}

export default GetQuestionForm;
